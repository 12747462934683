<template>

    <v-container fluid class="px-6">

        <v-card >

            <v-card-title class="px-6 card-header white--text">
                <v-icon class="mr-3 white--text">mdi-cup-water</v-icon> <h3>Station Parameter Registration Form</h3>
            </v-card-title>

            <v-form class="px-4" >

                <v-container fluid style="max-height: calc(100vh - 270px);overflow-y:auto;">
                    
                    <v-row>
                        <v-col cols="12" sm="6" md="3">
                            <v-subheader class="pa-1 text--text">
                                <v-icon class="mr-2 text--text">mdi-store-marker</v-icon>
                                Sampling Type
                            </v-subheader>
                            <v-select
                            :items="itemSamplingType"
                            v-model="SamplingTypeSelected"
                            label="Sampling Type"
                            required
                            outlined 
                            dense
                            @change="loadAllStation(SamplingTypeSelected)"
                            ></v-select>
                        </v-col>

                        <v-col cols="12" sm="6" md="3">
                            <v-subheader class="pa-1 text--text">
                                <v-icon class="mr-2 text--text">mdi-store-24-hour</v-icon>
                                Station
                            </v-subheader>
                            <v-autocomplete
                            v-on:change="selectValueStation"
                            :items="itemStation"
                            label="Station"
                            required
                            outlined 
                            dense
                            :loading="loadingDropdownStation"
                            hint="Please select sammpling type first"
                            :persistent-hint="SamplingTypeSelected == ''"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="6" md="3">
                            <v-subheader class="pa-1 text--text">
                                <v-icon class="mr-2 text--text">mdi-text-box-search-outline</v-icon>
                                Search in List Parameter
                            </v-subheader>

                            <v-text-field
                            v-model="searchParam"
                            type="text"
                            placeholder="Search"
                            outlined
                            dense
                            autocomplete="off"
                            @input="getTextSearch($event)"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-divider></v-divider>
        
                    <v-row>

                        <!-- Column List Checkbox Parameter -->
                        <v-col cols="12" sm="6">

                            <v-subheader class="pa-1 text--text">
                                <v-icon class="mr-2 text--text">mdi-water</v-icon>
                                List Parameter
                            </v-subheader>

                            <v-card
                            :loading="loadingLeft"
                            height="400px" outlined
                            >
                                <v-form>
                                    <v-container height="400px" class="scroll">


                                        <div class="item-param" v-for="item in checkboxParam" :key="item.value">

                                            <input
                                            :id="item.value+'_ACTIVE'"
                                            class="input-checkbox"
                                            :value="[item.text,item.value]"
                                            type="checkbox" 
                                            v-model="arrayParamChecked" 
                                            @change="checkedValueParam($event,item.text,item.value)">
                                            <span style="padding-left:20px;">
                                                {{item.text.includes('(NULL)')? item.text.split(" (NULL)")[0] : item.text}}
                                            </span>

                                        </div>

                                        
                                    </v-container>
                                </v-form>
                            </v-card>
                        </v-col>

                        <!-- Column List Selected Parameter -->
                        <v-col
                        cols="12"
                        sm="6" class="pr-8"
                        >
                        <v-subheader class="pa-1 text--text">
                            <v-icon class="mr-2 text--text">mdi-water-plus</v-icon>
                            Parameter For Station {{valStation}}
                        </v-subheader>
                            <v-card
                            :loading="loadingRight"
                            height="400px" outlined
                            >
                                <v-form>
                                    <v-container height="400px" class="scroll">
                                        
                                        <span class="black--text" style="font-size:0.9em;font-weight:bold;">
                                            * Click on parameter below to remove parameter from station
                                        </span>

                                        <div class="item-param-selected" v-for="item in arrayParamChecked" :key="item.value">
                                            <span @click="removeParamThis(item[0],item[1])">
                                                <v-icon style="color:#0199be;">mdi-water</v-icon>
                                                <span style="padding-left:10px;margin:5px;">
                                                <!-- {{item[0]}} -->
                                                {{item[0].includes('(NULL)')? item[0].split(" (NULL)")[0] : item[0]}}
                                                </span>  
                                            </span>
                                        </div>

                                        
                                    </v-container>
                                </v-form>
                            </v-card>
                        </v-col>


                    </v-row>

                    <!--<v-row justify="center" class="pb-4 pt-4">
                        <v-btn
                        @click="postParam"
                        class="mr-4 button white--text"
                        >
                            SET PARAMETER
                        </v-btn>
                    </v-row>-->

                </v-container>

                <v-divider></v-divider>

                <v-row justify="center" class="pb-4 pt-4">
                    <v-btn
                    @click="postParam()"
                    :disabled="arrayParamChecked.length == 0"
                    class="mr-4 button white--text"
                    >
                        SET PARAMETER
                    </v-btn>
                </v-row>

            </v-form>
        </v-card>

        <br>

        <!-- ------------------------------------ -->

        <!-- Popups/Dialogs Success Param Added-->
        <div class="text-center">
            <v-dialog
            v-model="dialogSuccessParamAdded"
            width="500"
            >
            <v-card>
                <v-card-title class="text-h5 white--text button">
                    <v-icon class="white--text mr-2">mdi-text-box-check</v-icon>Form has been submitted
                </v-card-title>

                <v-card-text class="pa-5 d-flex justify-center">
                    <h3>Parameters has been updated</h3> 
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="dialogSuccessParamAdded = false;reloadThis()"
                >
                    Close
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>

        <!-- Popups/Dialogs Error Station Null-->
        <div class="text-center">
            <v-dialog
            v-model="dialogErrorStation"
            width="500"
            >
            <v-card>
                <v-card-title class="text-h5 error white--text">
                Error! <v-icon style="color:white;margin-left:15px;">mdi-alert-circle</v-icon>
                </v-card-title>

                <v-card-text style="padding:20px 24px;font-weight:bold;">
                Something when wrong. Please select station first and make sure your internet is connected before add paramater.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="error--text"
                    text
                    @click="dialogErrorStation = false"
                >
                    OK
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>


        

        
    </v-container>
</template>

<script>
import axios from 'axios';
import ForgotPasswordVue from '../../User/Login/ForgotPassword.vue';

export default {

  data: () => ({

    APIStationDataPost: [],
    stationDataPost: [],

    itemSamplingType: ["Continuous","Manual"],
    SamplingTypeSelected : "",

    searchParam: "",

    loadingDropdownStation: false,
    itemStation: [],
    valStation : null,

    listAllParams: [],
    listAllParamsSearch: [],
    checkboxParam: [],
    listDetailsParamActive: [],
    arrayParamChecked: [],

    listFullNameParamSelected: [],

    minValueParam: [],

    successDialogParamAdded: false,
    paramDataPost: [],

    checkStatusAdd: null,

    dialogSuccessParamAdded: false,
    dialogErrorStation: false,

    loadingLeft: false,
    loadingRight: false,
        
  }),

  mounted() {
      
        // this.loadAllStation();
        // this.loadAllListParameter();

  },

  methods: {

    removeitemArray(arr) {
        var what, a = arguments, L = a.length, ax;
        while (L > 1 && arr.length) {
            what = a[--L];
            while ((ax= arr.indexOf(what)) !== -1) {
                arr.splice(ax, 1);
            }
        }
        return arr;
    },

    reloadThis(){
        window.location.reload()
    },

    loadAllStation(mod){

        this.loadAllListParameter(mod)

        this.loadingDropdownStation = true;

        this.listAllParamsSearch = [];
        this.checkboxParam = [];
        this.listDetailsParamActive = [];
        this.arrayParamChecked = [];
        this.paramDataPost = [];
        this.stationDataPost = [];


        let urlStation = "";
        if(mod == "Continuous"){
            urlStation = "mqm2/miqims/stations";
        }
        else if(mod == "Manual"){
            urlStation = "mqm2/miqims/stations_manual";
        }

        this.stationDataPost = [];
        this.itemStation = [];

        axios.get(this.globalUrl+urlStation, {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {
            console.log(response.data);

            // this.stationDataPost = result;
            this.stationDataPost = response.data;
            this.APIStationDataPost = response.data;
            // console.log(this.stationDataPost);

            

            for (let i = 0; i < response.data.length; i++) {
                this.itemStation.push(response.data[i].STATION_ID + " - " + response.data[i].LOCATION);
            }

            this.loadingDropdownStation = false;
            
        })
        .catch(error => {
            console.log(error);
            this.loadingDropdownStation = false;
        })
    },

    loadAllListParameter(mod){

        this.listAllParams = [];

        let urlParams = ""
        if(mod == "Continuous"){
            urlParams = "mqm2/miqims/params"
        }
        else if(mod == "Manual"){
            urlParams = "mqm2/miqims/params_manual"
        }

        axios.get(this.globalUrl+urlParams, {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {

            for (let i = 0; i < response.data.length; i++) {
                // if (response.data[i].value != "WQI" && response.data[i].value != "SI_DO_SAT"
                // && response.data[i].value != "SI_BOD" && response.data[i].value != "SI_COD"
                // && response.data[i].value != "SI_NH3N" && response.data[i].value != "SI_TSS") {
                //     this.listAllParams.push(response.data[i])
                // }

                if (response.data[i].value != "WQI" && response.data[i].value != "SI_BOD" && response.data[i].value != "SI_COD"
                && response.data[i].value != "SI_NH3N") {
                    this.listAllParams.push(response.data[i])
                }
            }
            
        })
        .catch(error => {
            console.log(error);
        })
        
    },

    getTextSearch: function(textSearch) {

        this.checkboxParam = this.listAllParamsSearch

        this.checkboxParam = this.checkboxParam.filter(item => {
            return item.text.toLowerCase().includes(textSearch);
        });

    },

    selectValueStation: function(e) {

        this.loadingLeft = true;
        this.loadingRight = true;

        this.listAllParamsSearch = [];
        this.checkboxParam = [];
        this.listDetailsParamActive = [];
        this.arrayParamChecked = [];
        this.paramDataPost = [];
        this.stationDataPost = [];

        this.valStation = e.split(' - ')[0];

        let urlStation = ""
        if(this.SamplingTypeSelected == "Continuous"){
            urlStation = "mqm2/miqims/stations"
        }
        else{
            urlStation = "mqm2/miqims/stations_manual"
        }
        
        axios.get(this.globalUrl+urlStation, {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {
            this.stationDataPost = response.data;
        })
        .catch(error => {
            console.log(error);
        })
            
        let myInterval1 = setInterval(() => {

                let stationData = [];
                for (let i = 0; i < this.APIStationDataPost.length; i++) {
                    if (this.APIStationDataPost[i].STATION_ID ==  this.valStation) {
                    stationData.push(this.APIStationDataPost[i]);
                    }
                }
                // console.log(this.APIStationDataPost);
                // console.log(stationData);

                let stationActive = [];
                let stationInactive = [];

                if(stationData.length != 0){

                    let keyData = Object.keys(stationData[0]);
                    for (let x = 0; x < keyData.length; x++) {
                        if (stationData[0][keyData[x]] == null || stationData[0][keyData[x]] == "") {
                            if (keyData[x].includes("ACTIVE")) {
                            //  console.log(keyData[x]);
                                stationInactive.push(keyData[x]);
                            }
                        // console.log(keyData[x]);
                        }
                        else if(stationData[0][keyData[x]] != null || stationData[0][keyData[x]] != ""){
                            if (keyData[x].includes("ACTIVE")) {
                                //  console.log(keyData[x]);
                                stationActive.push(keyData[x]);
                            }
                        }
                    }
                    
                    // console.log(this.listAllParams);
                    for(let i in this.listAllParams){
                        if(this.listAllParams[i].unit != "Index"){
                            this.checkboxParam.push(this.listAllParams[i])
                        }
                    }
                    // this.checkboxParam = this.listAllParams;
                    this.listAllParamsSearch = this.checkboxParam;

                    // Listkan Param Belah Kanan
                    let storeJap = [];
                    for (let k = 0; k < this.listAllParams.length; k++) {
                        for (let j = 0; j < stationActive.length; j++) {
                            if (this.listAllParams[k].value+"_ACTIVE" === stationActive[j] || this.listAllParams[k].value+"_ACTIVE" === "_"+stationActive[j]) {
                                storeJap.push(this.listAllParams[k])
                                this.listDetailsParamActive.push(this.listAllParams[k])
                            }
                        } 
                    }

                    for (let z = 0; z < storeJap.length; z++) {
                        this.arrayParamChecked.push([storeJap[z].text,storeJap[z].value]);
                    }

                }

                this.loadingLeft = false;
                this.loadingRight = false;
            

            clearInterval(myInterval1);
        }, 1000)
            
        

    },

    checkedValueParam(evenCheck,texName,paramValue){

        console.log(paramValue);
        if(paramValue === 'SI_pH'){
            paramValue = 'SIPH';
        }

        if(paramValue === 'SI_DO_SAT'){
            paramValue = 'SIDO_SAT';
        }

        if(paramValue === 'SI_TSS'){
            paramValue = 'SISS';
        }

        this.paramDataPost.push({
            STATION_ID: null
        });

        this.paramDataPost.forEach(function(res){
            res[paramValue+'_MIN'] = null;
            res[paramValue+'_MAX'] = null;
        })

        
        let keyDataStationDataPost = Object.keys(this.stationDataPost[0]);
        // console.log(keyDataStationDataPost);
        if (evenCheck.target.checked == true) {
            for (let lol = 0; lol < this.stationDataPost.length; lol++) {
                if (this.stationDataPost[lol].STATION_ID == this.valStation) {
                    // console.log(this.stationDataPost[lol]);
                    for (let i = 0; i < keyDataStationDataPost.length; i++) {
                        if (keyDataStationDataPost[i] == paramValue+"_ACTIVE") {
                            this.stationDataPost[lol][keyDataStationDataPost[i]] = paramValue;
                        }
                        else if ("_"+keyDataStationDataPost[i] == paramValue+"_ACTIVE") {
                            this.stationDataPost[lol][keyDataStationDataPost[i]] = paramValue.substr(1, 50); 
                        }
                    }
                    
                }
            }
        }
        else if (evenCheck.target.checked == false) {
            for (let lol = 0; lol < this.stationDataPost.length; lol++) {
                if (this.stationDataPost[lol].STATION_ID == this.valStation) {
                    // console.log(this.stationDataPost[lol]);
                    for (let i = 0; i < keyDataStationDataPost.length; i++) {
                        if (keyDataStationDataPost[i] == paramValue+"_ACTIVE" || "_"+keyDataStationDataPost[i] == paramValue+"_ACTIVE") {
                            this.stationDataPost[lol][keyDataStationDataPost[i]] = null;
                        }
                    }
                }
            }
        }
        

    },

    removeParamThis(param,value){

        for (let i = 0; i < this.arrayParamChecked.length; i++) {
            if (this.arrayParamChecked[i][0] == param) {

                const index = this.arrayParamChecked.indexOf(this.arrayParamChecked[i]);
                
                if (index > -1) {
                    this.arrayParamChecked.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
        }

        // this.checkedValueParam(param,value,false)
        let keyData = Object.keys(this.stationDataPost[0])
        for (let lol = 0; lol < this.stationDataPost.length; lol++) {
            if (this.stationDataPost[lol].STATION_ID == this.valStation) {
                // console.log(this.stationDataPost[lol]);
                for (let i = 0; i < keyData.length; i++) {
                    if (keyData[i] == value+"_ACTIVE" || "_"+keyData[i] == value+"_ACTIVE") {
                        this.stationDataPost[lol][keyData[i]] = null;
                    }
                }
                
            }
        }

    },


    // isNumber: function(evt) {
    //     evt = (evt) ? evt : window.event;
    //     var charCode = (evt.which) ? evt.which : evt.keyCode;
    //     if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
    //         evt.preventDefault();
    //     } else {
    //         return true;
    //     }
    // },

    // getMinumumValue: function(value,item) {

    //     let keyData = Object.keys(this.paramDataPost[0])
    //     // console.log(keyData);
    //     for (let i = 0; i < keyData.length; i++) {
    //         if (keyData[i] === item[1]+"_MIN") {
    //             this.paramDataPost[0][keyData[i]] = value;
    //         }
    //     }
        
    // },

    // getMaximumValue: function(value,item) {

    //     let keyData = Object.keys(this.paramDataPost[0])
    //     // console.log(keyData);
    //     for (let i = 0; i < keyData.length; i++) {
    //         if (keyData[i] === item[1]+"_MAX") {
    //             this.paramDataPost[0][keyData[i]] = value;
    //         }
    //     }
        
    // },

    postParam(){

        if (this.valStation != null) {

            console.log(this.valStation);

            let stationId = null;
            stationId = this.valStation;

            let jsonDataEditStation = "";

            console.log("AAAAAAAAA",this.stationDataPost);

            this.stationDataPost.forEach(function(res){
                if (res["STATION_ID"] == stationId) {
                    //jsonDataEditStation = JSON.stringify(res);
                    jsonDataEditStation = res;

                    jsonDataEditStation["stationId"] =  res.STATION_ID; 
                    delete jsonDataEditStation["STATION_ID"];

                    jsonDataEditStation["stationStatus"] =  res.STATION_STATUS; 
                    delete jsonDataEditStation["STATION_STATUS"];

                    jsonDataEditStation["alertStatus"] =  res.ALERT_STATUS; 
                    delete jsonDataEditStation["ALERT_STATUS"];

                    jsonDataEditStation["location"] =  res.LOCATION; 
                    delete jsonDataEditStation["LOCATION"];

                    jsonDataEditStation["longitude"] =  res.LONGITUDE; 
                    delete jsonDataEditStation["LONGITUDE"];

                    jsonDataEditStation["latitude"] =  res.LATITUDE; 
                    delete jsonDataEditStation["LATITUDE"];

                    jsonDataEditStation["stateName"] =  res.STATE_NAME; 
                    delete jsonDataEditStation["STATE_NAME"];

                    jsonDataEditStation["telecommunicationType"] =  res.TELECOMMUNICATION_TYPE; 
                    delete jsonDataEditStation["TELECOMMUNICATION_TYPE"];

                    jsonDataEditStation["landStructure"] =  res.LAND_STRUCTURE; 
                    delete jsonDataEditStation["LAND_STRUCTURE"];

                    jsonDataEditStation["soilType"] =  res.SOIL_TYPE; 
                    delete jsonDataEditStation["SOIL_TYPE"];

                    jsonDataEditStation["picName"] =  res.PIC_NAME; 
                    delete jsonDataEditStation["PIC_NAME"];

                    jsonDataEditStation["picPhone"] =  res.PIC_PHONE; 
                    delete jsonDataEditStation["PIC_PHONE"];

                    jsonDataEditStation["picEmail"] =  res.PIC_EMAIL; 
                    delete jsonDataEditStation["PIC_EMAIL"];

                    jsonDataEditStation["stationPicture"] =  res.STATION_PICTURE; 
                    delete jsonDataEditStation["STATION_PICTURE"];

                    jsonDataEditStation["deploymentPicture"] =  res.DEPLOYMENT_PICTURE; 
                    delete jsonDataEditStation["DEPLOYMENT_PICTURE"];

                    jsonDataEditStation["upstreamPicture"] =  res.UPSTREAM_PICTURE; 
                    delete jsonDataEditStation["UPSTREAM_PICTURE"];

                    jsonDataEditStation["downStreamPicture"] =  res.DOWNSTREAM_PICTURE; 
                    delete jsonDataEditStation["DOWNSTREAM_PICTURE"];

                    jsonDataEditStation["timeAlertTransmission"] =  res.TIME_ALERT_TRANSMISSION; 
                    delete jsonDataEditStation["TIME_ALERT_TRANSMISSION"];

                }
            });

            // console.log(this.stationDataPost);
            

            let keyLast = Object.keys(jsonDataEditStation)
            for(let i in this.arrayParamChecked){
                // console.log(this.arrayParamChecked[i][1]);
                for(let x in keyLast){
                    if(keyLast[x]?.split("_ACTIVE")[0] == this.arrayParamChecked[i][1]){
                        jsonDataEditStation[keyLast[x]] = this.arrayParamChecked[i][1]
                    }
                }
            }

            console.log(jsonDataEditStation);
            console.log(this.arrayParamChecked);
            console.log(this.checkboxParam);

            let urlEditStation = ""
            if(this.SamplingTypeSelected == "Continuous"){
                urlEditStation = "mqm2/miqims/editstation"
            }
            else{
                urlEditStation = "mqm2/miqims/editstation_manual"
            }

            // API PUT EDIT STATION
            axios.put(this.globalUrl+urlEditStation,
            jsonDataEditStation, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                //console.log(response.data);
                this.checkStatusAdd = "success";
                this.listAllParamsSearch = [];
                this.checkboxParam = [];
                this.listDetailsParamActive = [];
                this.arrayParamChecked = [];
                this.dialogSuccessParamAdded = true;   
            })
            .catch(error => {
                this.checkStatusAdd = "failed"; 
                console.log(error.response);
            })

            let myInterval2 = setInterval(() => {                
                if (this.checkStatusAdd == "success") {
                    this.dialogSuccessParamAdded = true;
                }
                else if(this.checkStatusAdd == "failed"){
                    this.dialogErrorStation = true;
                }
                clearInterval(myInterval2);
            }, 1000)
            
        }

        else{
            console.log("make sure station is selected");
            this.dialogErrorStation = true;
        }

    }

  },

  computed: {
    
        // filterListParam(){
        //     if(this.searchParam){
        //         return this.checkboxParam.filter((item)=>{
        //             // return this.searchParam.toLowerCase().split(' ').every(v => item.fullname.toLowerCase().includes(v))
        //             return item.fullname.match(this.searchParam)
        //         })
        //     }
        //     else{
        //         return this.checkboxParam;
        //     }
        // }

  },
}
</script>

<style lang="scss">

    // HEADER PART

    // CONTAINER LEFT
    .scroll {
        overflow-y: scroll;
        height: 400px;
        padding: 20px;
    }

    .item-param{
        padding:10px 0px;
        padding-left: 20px;
    }

    .item-param:hover{
        background: #e1f3f7;
        color: #35aecb;
    }

    .item-param > input{
        cursor: pointer;
    }

    .item-param-selected{
        display: flex;
        // background: #e1f3f7;
        background-image: linear-gradient(to right, #e1f3f7, #ffffff) ;
        margin: 5px 0px;
    }


    .item-param-selected:hover{
        display: flex;
        background: #caedf4;
        cursor: pointer;
    }

    .item-param-selected > span{
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        padding: 2px 12px;
    }

    .value-after-selected{
        margin: 0px 5px !important;
        padding: 5px !important;
    }

</style>